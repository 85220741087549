import React from 'react'

const Forgotpassword = () => {
  return (
    <div>
      Forgotpassword
    </div>
  )
}

export default Forgotpassword
